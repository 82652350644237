<template>
  <Dialog :dialog="dialog" :dialogWidth="600">
    <template v-slot:title>Two-factor authentication</template>
    <template v-slot:body>
      <v-form ref="uploadForm" v-model="form_valid" lazy-validation>
        <div
          v-if="two_factor_enabled"
          key="verified"
          class="d-flex align-center flex-column my-5"
        >
          <v-img
            width="150px"
            contain
            :src="$assetURL('media/bg/2fa-success.png')"
          />
          <p class="my-5 green--text font-size-h2 bold-600">
            Two-factor authentication verified
          </p>
          <p class="font-size-h6">Your email is set to {{ email }}.</p>
          <p class="font-size-h6">
            Two-factor authentication codes will be sent to this email for
            login.
          </p>
        </div>
        <div v-else key="verification-pending">
          <div v-if="otp_sent" key="otp-sent" class="text-center">
            <label class="font-size-h6 text-dark"
              >Enter One-Time Password (OTP) for two-factor authentication below
              we send to {{ is_2fa.email }}</label
            >
            <v-otp-input
              class="otp-input mt-8"
              type="number"
              hide-details
              v-model="is_2fa.otp"
              :disabled="isLoading"
              :loading="isLoading"
              length="6"
            ></v-otp-input>
            <v-btn
              :disabled="isLoading"
              depressed
              tile
              text
              color="blue darken-4"
              class="mt-4"
              v-on:click="sendOTP()"
            >
              <span class="bold-500">Resend Code</span>
            </v-btn>
          </div>
          <div v-else key="enter-your-valid-email">
            <label class="font-size-h6 text-dark"
              >You'll receive a One-Time Password (OTP) at this email address
              every time you sign in to your account.</label
            >
            <label class="font-size-h6 font-weight-bolder text-dark mt-5"
              >Email</label
            >
            <EmailInput
              dense
              id="email"
              v-model="email"
              placeholder="Enter your valid email"
              :disabled="isLoading"
              :loading="isLoading"
              class="mt-0"
            ></EmailInput>
            <p
              class="font-size-h6 mt-4 text-dark orange--text text--darken-4 bold-500"
            >
              Note: This email address will be set as your primary email, and
              only you will be able to log in using this email.
            </p>
          </div>
        </div>
      </v-form>
    </template>
    <template v-slot:action>
      <div v-if="two_factor_enabled">
        <v-btn
          depressed
          tile
          :disabled="isLoading"
          v-on:click="$emit('success', true)"
        >
          Close
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          depressed
          tile
          :disabled="isLoading"
          v-on:click="$emit('close', true)"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="otp_sent"
          key="btn-verify-otp"
          class="ml-4 white--text"
          depressed
          color="blue darken-4"
          tile
          :disabled="isLoading"
          :loading="isLoading"
          v-on:click="verifyOTP()"
        >
          Verify
        </v-btn>
        <v-btn
          v-else
          key="btn-sent-otp"
          class="ml-4 white--text"
          depressed
          color="blue darken-4"
          tile
          :disabled="isLoading"
          :loading="isLoading"
          v-on:click="sendOTP()"
        >
          Next
        </v-btn>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import EmailInput from "@/view/components/EmailInput";
import { POST } from "@/core/services/store/request.module";

export default {
  name: "TwoFactorAuth",
  components: {
    Dialog,
    EmailInput,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: null,
      two_factor_enabled: false,
      otp_sent: false,
      form_valid: false,
      isLoading: false,
      is_2fa: {
        token: null,
        email: null,
        expiry: null,
        otp: null,
      },
    };
  },
  methods: {
    sendOTP() {
      this.isLoading = true;
      this.$store
        .dispatch(POST, {
          url: "auth/2fa/send-otp",
          data: { email: this.email },
        })
        .then((response) => {
          this.otp_sent = true;
          this.is_2fa = {
            token: response.token,
            email: response.email,
            expiry: response.expiry,
            otp: null,
          };
        })
        .catch(() => {
          // console.log(response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    verifyOTP() {
      this.isLoading = true;
      this.$store
        .dispatch(POST, {
          url: "auth/2fa/verify-otp",
          data: { ...this.is_2fa, email: this.email },
        })
        .then(() => {
          this.two_factor_enabled = true;
        })
        .catch(() => {
          // console.log(response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
